<template>
  <div v-if="features">
    <div
      class="panel-menu"
      :class="{
        'open': $store.state.menu.isOpen,
        'ie-warning': isBrowserIe,
        'mobile-active': $store.state.menu.mobile
      }"
    >
      <ProjectSelect
        v-if="isActive(features.MultiProjects)"
        class="panel-menu-projects"
        @set-project-create="showProjectCreate = true"
        @set-project-invite="onSetProjectInvite"
      />
      <PerfectScrollbar
        class="panel-menu-ps"
        :class="{ 'mobile' : $store.state.menu.mobile }"
      >
        <div class="panel-menu-list">
          <div class="panel-menu-list-features">
            <span
              v-if="isTwoFeatures || (isItGlobal && isActive(features.Vstack))"
              class="panel-menu-list-features-combo"
            >
              <span
                class="panel-menu-list-features-combo-label"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'vstack'}"
                @click="openMenu('vstack')"
              >
                <span
                  class="panel-menu-list-features-combo-label-icon"
                  v-html="icons.ICON_VSTACK_SERVER"
                />
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-name"
                >
                  {{ isItGlobal ? 'vStack Public Cloud' : $t('layout.menu.features.vstack') }}
                </span>
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-arrow"
                  v-html="ICON_MENU_ARROW"
                />
              </span>
              <span
                class="panel-menu-list-features-combo-list"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'vstack'}"
              >
                <LayoutRouterLink
                  :to="{ name: 'vstack-list' }"
                  :name="isItGlobal ? 'Servers' : $t('layout.menu.features.vstack-servers')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="vstack/servers"
                  custom-active
                />
                <LayoutRouterLink
                  :to="{ name: 'vstacknetworks-list' }"
                  :name="isItGlobal ? 'Networks' : $t('layout.menu.features.vstack-networks')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="vstack/networks"
                  custom-active
                />
                <LayoutRouterLink
                  :to="{ name: 'vstack-edge-list' }"
                  :name="isItGlobal ? 'Gateways' : $t('layout.menu.features.vstack-edges')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="vstack/edges"
                  custom-active
                />
              </span>
            </span>
            <span
              v-if="isTwoFeatures || (isItGlobal && isActive(features.SIWithImage))"
              class="panel-menu-list-features-combo"
            >
              <span
                class="panel-menu-list-features-combo-label"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'vmware'}"
                @click="openMenu('vmware')"
              >
                <span
                  class="panel-menu-list-features-combo-label-icon"
                  v-html="icons.ICON_VMWARE"
                />
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-name"
                >
                  {{ $t('layout.menu.features.vmware') }}
                </span>
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-arrow"
                  v-html="ICON_MENU_ARROW"
                />
              </span>
              <span
                class="panel-menu-list-features-combo-list"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'vmware'}"
              >
                <LayoutRouterLink
                  v-if="isActive(features.SIWithImage)"
                  :to="{ name: 'vmware-servers-list' }"
                  :name="isItGlobal ? 'Servers' : $t('layout.menu.features.vmware-servers')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="servers"
                />
                <LayoutRouterLink
                  v-if="isActive(features.SIWithImage)"
                  :to="{ name: 'vmware-templates-list' }"
                  :name="isItGlobal ? 'Servers templates' : $t('layout.menu.features.vmware-templates')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="server-templates"
                />
                <LayoutRouterLink
                  v-if="isAnyActive(features.PublicNetwork, features.PrivateNetwork)"
                  :to="{ name: 'vmware-networks-list' }"
                  :name="isItGlobal ? 'Networks' : $t('layout.menu.features.vmware-networks')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="networks"
                />
              </span>
            </span>
            <LayoutRouterLink
              v-if="!isItGlobal && !isTwoFeatures && isActive(features.Vstack)"
              :to="{ name: 'vstack-list' }"
              :name="isItGlobal ? 'Servers' : $t('layout.menu.features.vstack-servers')"
              :icon-html="icons.ICON_VSTACK_SERVER"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vstack/servers"
            />
            <LayoutRouterLink
              v-if="!isItGlobal && !isTwoFeatures && isActive(features.Vstack)"
              :to="{ name: 'vstacknetworks-list' }"
              :name="isItGlobal ? 'Networks' : $t('layout.menu.features.vstack-networks')"
              :icon-html="icons.ICON_VSTACK_NETWORK"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vstack/networks"
            />
            <LayoutRouterLink
              v-if="!isItGlobal && !isTwoFeatures && isActive(features.Vstack)"
              :to="{ name: 'vstack-edge-list' }"
              :name="isItGlobal ? 'Gateways' : $t('layout.menu.features.vstack-edges')"
              :icon-html="icons.ICON_VSTACK_EDGE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vstack/edges"
            />
            <LayoutRouterLink
              v-if="!isItGlobal && !isTwoFeatures && isActive(features.SIWithImage)"
              :to="{ name: 'vmware-servers-list' }"
              :name="isItGlobal ? 'Servers' : $t('layout.menu.features.vmware-servers')"
              :icon-html="icons.ICON_VMWARE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="servers"
            />
            <LayoutRouterLink
              v-if="!isItGlobal && !isTwoFeatures && isActive(features.SIWithImage)"
              :to="{ name: 'vmware-templates-list' }"
              :name="isItGlobal ? 'Servers templates' : $t('layout.menu.features.vmware-templates')"
              :icon-html="icons.ICON_VMWARE_TEMPLATE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="server-templates"
            />
            <LayoutRouterLink
              v-if="!isItGlobal && !isTwoFeatures && isAnyActive(features.PublicNetwork, features.PrivateNetwork)"
              :to="{ name: 'vmware-networks-list' }"
              :name="isItGlobal ? 'Networks' : $t('layout.menu.features.vmware-networks')"
              :icon-html="icons.ICON_VMWARE_NETWORK"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="networks"
            />
            <LayoutRouterLink
              v-if="isActive(features.CloudDirector, 'HasPublic') && isItGlobal"
              :to="{ name: 'vcd-list' }"
              :name="$t('layout.menu.features.cloud-director-alt')"
              :icon-html="icons.ICON_CLOUD_DIRECTOR"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vcd"
            />
            <LayoutRouterLink
              v-if="isActive(features.CloudDirector, 'HasPublic') && !isItGlobal"
              :to="{ name: 'vcd-list' }"
              :name="isItGlobal ? 'VMware Public Cloud' : $t('layout.menu.features.cloud-director')"
              :icon-html="icons.ICON_CLOUD_DIRECTOR"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vcd"
            />
            <LayoutRouterLink
              v-if="isDomRu"
              :to="{ name: 'vdi-cloud-list' }"
              name="VDI Cloud"
              :icon-html="icons.ICON_VMWARE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vdi-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isDomRu"
              :to="{ name: '3d-vdi-cloud-list' }"
              name="3D VDI Cloud"
              :icon-html="icons.ICON_VMWARE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="3d-vdi-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isActive(features.ManagedServers)"
              :to="{ name: 'managed-list' }"
              :name="isItGlobal ? 'Managed' : $t('layout.menu.features.managed')"
              :icon-html="icons.ICON_MANAGED"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="managed-server-subscriptions"
            />
            <LayoutRouterLink
              v-if="isActive(features.Kubernetes)"
              :to="{ name: 'k8s-list' }"
              :name="$t('layout.menu.features.k8s')"
              :icon-html="icons.ICON_K8S"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="kubernetes"
            />
            <LayoutRouterLink
              v-if="isActive(features.DNS)"
              :to="{ name: 'dns-domains' }"
              :name="$t('layout.menu.features.dns')"
              :icon-html="icons.ICON_DNS"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="dns"
              custom-active
            />
            <span
              v-if="isActive(features.Cdn)"
              class="panel-menu-list-features-combo"
            >
              <span
                class="panel-menu-list-features-combo-label"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'cdn'}"
                @click="openMenu('cdn')"
              >
                <span
                  class="panel-menu-list-features-combo-label-icon"
                  v-html="icons.ICON_CDN"
                />
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-name"
                >
                  {{ $t('layout.menu.features.cdn') }}
                </span>
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-arrow"
                  v-html="ICON_MENU_ARROW"
                />
              </span>
              <span
                class="panel-menu-list-features-combo-list"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'cdn'}"
              >
                <LayoutRouterLink
                  v-if="isActive(features.Cdn)"
                  :to="{ name: 'cdn-domains' }"
                  :name="isItGlobal ? 'Websites' : $t('layout.menu.features.cdn-sites')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="cdn"
                  custom-active
                />
                <LayoutRouterLink
                  v-if="isActive(features.Cdn)"
                  :to="{ name: 'cdn-s3-list' }"
                  :name="$t('layout.menu.features.cdns3')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="cdn-s3"
                  custom-active
                />
                <LayoutRouterLink
                  v-if="isActive(features.Cdn)"
                  :to="{ name: 'cdn-vod-list' }"
                  :name="$t('layout.menu.features.cdn-vod')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="cdn-vod"
                  custom-active
                />
              </span>
            </span>
            <LayoutRouterLink
              v-if="isActive(features.ConfiguredServers)"
              :to="{ name: 'configured-list' }"
              :name="isItGlobal ? 'Configured servers' : $t(`layout.menu.features.${namingVpnType}`)"
              :icon-html="icons.ICON_CONFIGURED"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="configured-servers"
              custom-active
            />
            <LayoutRouterLink
              v-if="isActive(features.Llm)"
              :to="{ name: 'gpt-list' }"
              name="GPT"
              :icon-html="icons.ICON_GPT"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="gpt"
              custom-active
              label="NEW"
            />
            <span
              v-if="isActive(features.UnocV2)"
              class="panel-menu-list-features-combo"
            >
              <span
                class="panel-menu-list-features-combo-label"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'unoc'}"
                @click="openMenu('unoc')"
              >
                <span
                  class="panel-menu-list-features-combo-label-icon"
                  v-html="icons.ICON_UNOC"
                />
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-name"
                >
                  {{ $t('layout.menu.features.unoc') }}
                </span>
                <span
                  v-if="$store.state.menu.isOpen"
                  class="panel-menu-list-features-combo-label-arrow"
                />
              </span>
              <span
                class="panel-menu-list-features-combo-list"
                :class="{'open': $store.state.menu.isOpen && openedMenu === 'unoc'}"
              >
                <LayoutRouterLink
                  :to="{ name: 'unoc-infobases' }"
                  :name="isItGlobal ? 'Bases' : $t('layout.menu.features.unoc-infobases')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="1c/infobases"
                />
                <LayoutRouterLink
                  :to="{ name: 'unoc-employees' }"
                  :name="isItGlobal ? 'Users' : $t('layout.menu.features.unoc-employees')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="1c/employees"
                />
                <LayoutRouterLink
                  :to="{ name: 'unoc-terminal-server' }"
                  :name="isItGlobal ? 'Remote Desktop' : $t('layout.menu.features.unoc-ts')"
                  :hide-name="!$store.state.menu.isOpen"
                  dir-name="1c/terminal-server"
                />
              </span>
            </span>
            <LayoutRouterLink
              v-if="isActive(features.Storage)"
              :to="{ name: 'storage-detail' }"
              :name="isItGlobal ? 'Storage' : $t('layout.menu.features.storage')"
              :icon-html="icons.ICON_STORAGE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="storage"
            />
            <LayoutRouterLink
              v-if="isActive(features.SSL)"
              :to="{ name: 'ssl-list' }"
              :name="$t('layout.menu.features.ssl')"
              :icon-html="icons.ICON_SSL"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="ssl"
            />
            <LayoutRouterLink
              v-if="isActive(features.CloudDirector, 'HasPrivate')"
              :to="{ name: 'vmware-private-list' }"
              name="VMware Private Cloud"
              :icon-html="icons.ICON_CLOUD_DIRECTOR"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vmware-private"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'ai-cloud-list' }"
              name="AI Cloud"
              :icon-html="icons.ICON_VMWARE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="ai-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'vmware-disaster-recovery-list' }"
              name="VMware Disaster Recovery"
              :icon-html="icons.ICON_VMWARE_TEMPLATE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vmware-disaster-recovery"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'vdi-cloud-list' }"
              name="VDI Cloud"
              :icon-html="icons.ICON_VMWARE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="vdi-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: '3d-vdi-cloud-list' }"
              name="3D VDI Cloud"
              :icon-html="icons.ICON_VMWARE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="3d-vdi-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'exchange-cloud-list' }"
              name="Exchange Cloud"
              :icon-html="icons.ICON_REFERRAL"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="exchange-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'communigate-pro-cloud-list' }"
              name="CommuniGate Pro Cloud"
              :icon-html="icons.ICON_AUTOMATION"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="communigate-pro-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 's3-storage-list' }"
              name="S3 Storage"
              :icon-html="icons.ICON_STORAGE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="s3-storage"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'managed-it-list' }"
              name="Managed IT"
              :icon-html="icons.ICON_MANAGED"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="managed-it"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'veeam-cloud-list' }"
              name="Veeam Cloud Connect"
              :icon-html="icons.ICON_VMWARE"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="veeam-cloud"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'ddos-protect-list' }"
              :name="isItGlobal ? 'DDoS protection' : $t('layout.menu.features.ddos-protect')"
              :icon-html="icons.ICON_SSL"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="ddos-protect"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'soc-list' }"
              name="Security Operation Center"
              :icon-html="icons.ICON_SSL"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="soc"
              custom-active
            />
            <LayoutRouterLink
              v-if="isItGlobal"
              :to="{ name: 'security-list' }"
              name="Security"
              :icon-html="icons.ICON_SSL"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="security"
              custom-active
            />
            <LayoutRouterLink
              v-if="isActive(features.Referral)"
              :to="{ name: 'referral' }"
              :name="isItGlobal ? 'Partners' : $t('layout.menu.features.referral')"
              :icon-html="icons.ICON_REFERRAL"
              :hide-name="!$store.state.menu.isOpen"
            />
            <LayoutRouterLink
              v-if="isActive(features.API) && isActive(features.Vstack)"
              :to="{ name: 'automation' }"
              :name="isItGlobal ? 'Automation' : $t('layout.menu.features.automation')"
              :icon-html="icons.ICON_AUTOMATION"
              :hide-name="!$store.state.menu.isOpen"
            />
            <LayoutRouterLink
              v-if="showFinance"
              :to="{ name: 'billing' }"
              :name="isItGlobal ? 'Finance' : $t('layout.menu.features.billing')"
              :icon-html="icons.ICON_BILLING"
              :hide-name="!$store.state.menu.isOpen"
              dir-name="billing"
            />
            <LayoutRouterLink
              v-if="showHalloweenAI"
              :to="{ name: 'halloween-ai' }"
              :name="$t('layout.menu.features.halloween-ai')"
              :icon-html="icons.ICON_HALLOWEEN"
              :hide-name="!$store.state.menu.isOpen"
              label="BOO!"
            />
            <LayoutRouterLink
              v-if="showNewYearPromo"
              :to="{ name: 'new-year-promo' }"
              :name="newYearPromoName"
              :icon-html="icons.ICON_NYPROMO"
              :hide-name="!$store.state.menu.isOpen"
              :label="$t('layout.menu.features.new-year-promo')"
            />
          </div>
          <div class="panel-menu-list-special">
            <LayoutRouterLink
              v-if="isActive(features.Support)"
              :to="{ name: 'tickets-list' }"
              :name="isItGlobal ? 'Support' : $t('layout.menu.features.support')"
              :icon-html="icons.ICON_SUPPORT"
              :hide-name="!$store.state.menu.isOpen"
              :notify-counter="msgSupportCount"
              dir-name="support"
            />
            <a
              v-if="isActive(features.SimpleOneSupport)"
              target="_blank"
              :href="simpleOneSupportLink"
              class="layout-router-link layout-router-link-blank"
              :class="{ 'hidden' : !$store.state.menu.isOpen }"
            >
              <span
                class="layout-router-link-icon"
                v-html="icons.ICON_SUPPORT"
              />
              <span
                v-if="$store.state.menu.isOpen"
                class="layout-router-link-name"
              >
                <span>
                  {{ isItGlobal ? 'Support' : $t('layout.menu.features.support') }}
                </span>
              </span>
            </a>
            <LayoutRouterLink
              :to="{ name: 'account-manage' }"
              :name="isItGlobal ? 'Settings' : $t('layout.menu.features.settings')"
              :icon-html="icons.ICON_SETTINGS"
              :hide-name="!$store.state.menu.isOpen"
            />
          </div>
          <span
            v-if="!$store.state.menu.mobile"
            class="panel-menu-switcher"
            :class="{'panel-menu-switcher-hidden': !$store.state.menu.isOpen }"
            @click="switchMenu"
            v-html="ICON_MENU_SWITCHER"
          />
        </div>
      </PerfectScrollbar>
    </div>
    <PopupProjectCreate
      v-if="showProjectCreate"
      @close="showProjectCreate=false"
      @success="onProjectCreate"
    />
    <PopupProjectInvite
      v-if="showProjectInvite"
      :project="selectedProjectInvite"
      @close="showProjectInvite=false"
      @accept="onProjectAccept"
      @decline="onProjectDecline"
    />
  </div>
  <div
    v-else
    class="panel-menu"
    :class="{'open': $store.state.menu.isOpen, 'ie-warning': isBrowserIe}"
  >
    <PerfectScrollbar
      class="panel-menu-ps"
      :class="{ 'mobile' : $store.state.menu.mobile }"
    >
      <div class="panel-menu-list">
        <div class="panel-menu-list-special">
          <router-link
            class="layout-router-link"
            :to="{ name: 'account-manage' }"
          >
            {{ isItGlobal ? 'Settings' : $t('layout.menu.features.settings') }}
          </router-link>
        </div>
      </div>
    </PerfectScrollbar>
  </div>
</template>

<script>
import LayoutRouterLink from './LayoutRouterLink.vue'
import PopupProjectCreate from '../features/projects/shared/PopupProjectCreate.vue'
import PopupProjectInvite from '../features/projects/shared/PopupProjectInvite.vue'
import ProjectSelect from '../features/projects/shared/ProjectSelect.vue'
import partner from '@/partner'
import feature from '@/feature'
import icons from '@/svg/features'
import { ICON_MENU_SWITCHER } from '@/svg/icons'
import { ICON_MENU_ARROW } from '@/svg/common'

import { COMMON_MENU_CHANGE } from '@/store/mutations.type'
import { COMMON_APP_SETTINGS, SUPPORT_TICKETS_SUM } from '@/store/actions.type'
import { SIMPLEONE_GET_LINK_SUPPORT, MOBILE_MENU_OPENED } from '@/store/getters.type'

export default {
  components: {
    ProjectSelect,
    LayoutRouterLink,
    PopupProjectCreate,
    PopupProjectInvite,
  },
  props: {
    isBrowserIe: { type: Boolean, required: false },
  },
  data () {
    let isTwoFeatures = (
      feature.isActiveFeature(feature.features.SIWithImage) ||
      feature.isActiveFeature(feature.features.PublicNetwork) ||
      feature.isActiveFeature(feature.features.PrivateNetwork)
    ) && feature.isActiveFeature(feature.features.Vstack)
    return {
      isTwoFeatures,
      features: feature.features,
      icons,
      ICON_MENU_SWITCHER,
      ICON_MENU_ARROW,
      openedMenu: null,
      showProjectCreate: false,
      showProjectInvite: false,
      intervalCounter: null,
    }
  },
  computed: {
    simpleOneSupportLink () {
      return this.$store.getters[SIMPLEONE_GET_LINK_SUPPORT]
    },
    isItGlobal () {
      return partner.isPartnerName(partner.partners.ITGLOBAL)
    },
    isDomRu () {
      return partner.isPartnerName(partner.partners.DOM_RU)
    },
    msgSupportCount () {
      if (this.isActive(this.features.Support)) {
        return this.$store.getters.getCountMsg
      } else {
        return 0
      }
    },
    namingVpnType () {
      return partner.isPartnerName(partner.partners.SS_IO) ||
              partner.isPartnerName(partner.partners.SS_US) ||
              partner.isPartnerName(partner.partners.SS_CA) ||
              partner.isPartnerName(partner.partners.SS_BR) ? 'vpn' : 'configured'
    },
    showFinance () {
      return feature.isActiveFeature(feature.features.Finances)
    },
    showHalloweenAI () {
      let availablePartner = partner.isPartnerName(partner.partners.SS_IO) ||
            partner.isPartnerName(partner.partners.SS_US) ||
            partner.isPartnerName(partner.partners.SS_RU) ||
            partner.isPartnerName(partner.partners.SS_KZ) ||
            partner.isPartnerName(partner.partners.SS_BY)

      function currentDateIsAvailable () {
        const currentDate = new Date()
        const targetDate = new Date(2023, 9, 29)

        return (
          currentDate.getDate() <= targetDate.getDate() &&
                currentDate.getMonth() <= targetDate.getMonth() &&
                currentDate.getFullYear() <= targetDate.getFullYear()
        )
      }
      return availablePartner && currentDateIsAvailable()
    },
    mobileMenuIsOpened () {
      return this.$store.getters[MOBILE_MENU_OPENED]
    },
    newYearPromoName () {
      if (partner.isPartnerName(partner.partners.SS_IO) || partner.isPartnerName(partner.partners.SS_US) || partner.isPartnerName(partner.partners.SS_CA)) {
        return this.$t('layout.menu.features.new-year-promo-christmas')
      } else {
        return this.$t('layout.menu.features.new-year-promo-ny')
      }
    },
    showNewYearPromo () {
      const registeredAt = new Date(this.$store.state.app.data.User.RegisteredAt)
      const currentTime = new Date()

      const westPartnerRange = {
        start: new Date('2023-12-11'),
        end: new Date('2023-12-26'),
      }
      const eastPartnerRange = {
        start: new Date('2023-12-18'),
        end: new Date('2023-12-28'),
      }
      westPartnerRange.start.setHours(0, 0, 0, 0)
      westPartnerRange.end.setHours(0, 0, 0, 0)
      eastPartnerRange.start.setHours(0, 0, 0, 0)
      eastPartnerRange.end.setHours(0, 0, 0, 0)

      const westPartner = partner.isPartnerName(partner.partners.SS_IO) || partner.isPartnerName(partner.partners.SS_US) || partner.isPartnerName(partner.partners.SS_CA)
      const eastPartner = partner.isPartnerName(partner.partners.SS_RU) ||
                        partner.isPartnerName(partner.partners.SS_BY) ||
                        partner.isPartnerName(partner.partners.SS_KZ) ||
                        partner.isPartnerName(partner.partners.SS_TR) ||
                        partner.isPartnerName(partner.partners.SS_IN)
      if ((westPartner && (registeredAt >= westPartnerRange.start && registeredAt <= westPartnerRange.end) && (currentTime >= westPartnerRange.start && currentTime <= westPartnerRange.end)) ||
        (eastPartner && (registeredAt >= eastPartnerRange.start && registeredAt <= eastPartnerRange.end) && (currentTime >= eastPartnerRange.start && currentTime <= eastPartnerRange.end))) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    '$route': function (newValue) {
      if (newValue.meta.feature && newValue.meta.feature.indexOf(feature.features.Vstack) >= 0) {
        this.openedMenu = 'vstack'
      } else if (newValue.meta.feature && (
        newValue.meta.feature.indexOf(feature.features.SIWithImage) >= 0 ||
        newValue.meta.feature.indexOf(feature.features.PrivateNetwork) >= 0 ||
        newValue.meta.feature.indexOf(feature.features.RoutedNetwork) >= 0 ||
        newValue.meta.feature.indexOf(feature.features.PublicNetwork) >= 0)
      ) {
        this.openedMenu = 'vmware'
      } else if (newValue.meta.feature && newValue.meta.feature.indexOf(feature.features.UnocV2) >= 0) {
        this.openedMenu = 'unoc'
      } else if (newValue.meta.feature && (
        newValue.meta.feature.indexOf(feature.features.Cdn) >= 0 ||
        newValue.meta.feature.indexOf(feature.features.CdnVod) >= 0 ||
        newValue.meta.feature.indexOf(feature.features.CdnS3) >= 0
      )) {
        this.openedMenu = 'cdn'
      } else {
        this.openedMenu = undefined
      }
    },
    'mobileMenuIsOpened': function (isOpen) {
      if (isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
  },
  created () {
    if (this.isActive(this.features.Support)) {
      this.checkSupportMessages()
      this.intervalCounter = setInterval(() => {
        this.checkSupportMessages()
      }, 10000)
    }
  },
  beforeUnmount () {
    clearInterval(this.intervalCounter)
    document.body.style.overflow = 'auto'
  },
  methods: {
    checkSupportMessages () {
      this.$store.dispatch(SUPPORT_TICKETS_SUM)
        .catch(() => {
          clearInterval(this.intervalCounter)
        })
    },
    isActive (fName, optionName) {
      return feature.isActiveFeature(fName, optionName)
    },
    isAnyActive (...list) {
      return feature.isAnyActiveFeature(...list)
    },
    openMenu (name) {
      if (!this.$store.state.menu.isOpen) {
        this.$store.commit(COMMON_MENU_CHANGE, true)
        this.openedMenu = name
      } else if (this.openedMenu !== name) {
        this.openedMenu = name
      } else {
        this.openedMenu = null
      }
    },
    onProjectCreate (projectId) {
      if (this.$store.state.project.current.Id === projectId) {
        this.$store.dispatch(COMMON_APP_SETTINGS, { pid: projectId, isForce: true })
          .then(() => {
            this.showProjectCreate = false
            this.$router.push({ name: 'account-manage', query: { pid: projectId }, hash: '#Project' })
          })
      } else {
        this.showProjectCreate = false
        this.$router.push({ name: 'account-manage', query: { pid: projectId }, hash: '#Project' })
      }
    },
    onSetProjectInvite (project) {
      this.selectedProjectInvite = project
      this.showProjectInvite = true
    },
    onProjectAccept (projectId) {
      this.$router.push({ name: 'account-manage', query: { pid: projectId }, hash: '#Project' })
      this.showProjectInvite = false
    },
    onProjectDecline () {
      this.$store.dispatch(COMMON_APP_SETTINGS, { pid: this.$store.state.project.current.Id, isForce: true })
      this.showProjectInvite = false
    },
    switchMenu () {
      this.$store.commit(COMMON_MENU_CHANGE, !this.$store.state.menu.isOpen)
    },
  },
}
</script>

<style lang="less" scoped>
.panel-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10005;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  padding-top: var(--header-height);
  overflow: hidden;
  background-color: var(--menu-color-bg);
  border-right: 1px solid var(--color-border);
  transition: all var(--menu-transition);

  &-switcher {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    min-height: 32px;
    margin-top: 24px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: auto;
    overflow: hidden;
    cursor: pointer;
    background-color: unset;
    border: unset;
    border-radius: var(--border-radius);
    transition: all var(--menu-transition);

    &:active,
    &:focus-visible {
      border: 2px solid var(--color-primary);
      outline: none;
    }

    :deep(.color-fill) {
      fill: var(--menu-color-icon);
    }

    :deep(.color-stroke) {
      stroke: var(--menu-color-icon);
    }

    :deep(rect) {
      transition: 0.2s;
    }

    &:hover {
      background-color: var(--menu-color-bg-active);

      :deep(g rect) {
        fill: var(--color-base);
        stroke: none;
      }

      :deep(rect) {
        stroke: var(--color-base);
      }
    }

    &-hidden {
      left: -9px;
      margin-right: 30px;
      margin-left: 30px;
      transform: rotate(180deg);
    }
  }

  &.ie-warning {
    padding-top: calc(var(--header-height) + 40px);
  }

  &-projects {
    width: 216px;
    margin: var(--margin-default) 8px;
  }

  &-ps {
    display: flex;
    flex: 1;
    align-items: stretch;

    &.mobile {
      padding: 0 8px;

      & .panel-menu-list-features-combo-label,
      & .layout-router-link {
        padding: 0 8px !important;
        border-radius: var(--border-radius);
      }
    }
  }

  &-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    &-features {
      flex: 1;

      &-combo {
        position: relative;
        display: flex;
        flex-direction: column;

        &-label {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 30px;
          font-weight: 500;
          color: var(--menu-color-text);
          text-decoration: none;
          cursor: pointer;
          transition: all var(--menu-transition);

          &:hover {
            & .panel-menu-list-features-combo-label-name {
              color: var(--menu-color-text-active);
            }
          }

          &-icon {
            display: flex;
            align-items: center;
            width: 15px;
            height: 20px;

            :deep(.color-fill) {
              fill: var(--menu-color-icon);
            }
          }

          &-name {
            flex: 1;
            margin-left: 15px;
            font-size: 14px;
            white-space: nowrap;
          }

          &-arrow {
            display: block;
            transition: all var(--menu-transition);
            transform: rotate(45deg);
          }

          &.open &-arrow {
            transform: rotate(90deg);
          }

          &.open,
          &:hover {
            color: var(--menu-color-text-active);
            background-color: var(--menu-color-bg-active);

            :deep(svg path) {
              fill: var(--menu-color-text-active);
            }
          }
        }

        &-list {
          display: flex;
          flex-direction: column;
          max-height: 0;
          overflow: hidden;
          transition: all var(--menu-transition);

          &.open {
            max-height: 130px;
            padding: 5px 0;
          }
        }
      }
    }

    &-special {
      margin-top: auto;
    }
  }

  & .layout-router-link-blank {
    display: flex;
    align-items: center;

    &.hidden {
      & .layout-router-link-icon {
        padding-left: 30px;
      }
    }

    &:hover {
      text-decoration: none;
      background-color: var(--menu-color-bg-active);
    }
  }

  :deep(.layout-router-link) {
    display: flex;
    height: 40px;
    font-weight: 500;
    color: var(--menu-color-text);

    &-blank {
      & .layout-router-link-name {
        margin-left: 15px;
      }

      &::after {
        display: none;
      }
    }

    &.router-link-active,
    &:hover {
      & svg.vpn path {
        fill: unset;
        stroke: var(--menu-color-text-active);
      }

      & svg path {
        fill: var(--menu-color-text-active);
      }

      & svg.halloween path:first-child {
        fill: unset !important;
        stroke: var(--menu-color-text-active);
      }

      & svg.halloween path:last-child {
        stroke: var(--menu-color-text-active);
      }

      & svg.halloween .eye {
        fill: var(--menu-color-text-active);
        stroke: unset !important;
      }

      & .layout-router-link-name {
        color: var(--menu-color-text-active);
      }
    }

    &-name {
      & .inner {
        white-space: nowrap;
      }
    }
  }

  &.open {
    width: var(--menu-width);

    :deep(.layout-router-link) {
      padding-left: 30px;
    }

    .panel-menu-list-features-combo-label {
      padding-left: 30px;
    }
  }
}

@media (max-width: 820px) {
  .panel-menu {
    display: none;
    width: 100% !important;
    border-right: none;

    &.mobile-active {
      display: flex;
    }
  }
}
</style>
